export const getElementsInViewport = (): Element[] => {
  const elements = Array.from(document.querySelectorAll('.react-grid-item'));

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  const filteredElements = elements.filter((element) => {
    const rect = element.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.right <= viewportWidth &&
      rect.bottom <= viewportHeight
    );
  });

  filteredElements.sort((a, b) => {
    const aRect = a.getBoundingClientRect();
    const bRect = b.getBoundingClientRect();
    return aRect.top - bRect.top;
  });

  return filteredElements;
};
