export const isWebView = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  return !!window?.webkit?.messageHandlers?.headerInfo?.postMessage;
};

export const postMessage = (token: string, user: any) => {
  if (typeof window === 'undefined') {
    return;
  }

  try {
    if ('webkit' in window) {
      if ('messageHandlers' in window.webkit) {
        if ('headerInfo' in window.webkit.messageHandlers) {
          if ('postMessage' in window.webkit.messageHandlers.headerInfo) {
            window.webkit.messageHandlers.headerInfo.postMessage({
              user: {
                ...user,
                avatar: user.avatar || '/images/profile-image.svg',
              },
              token,
            });
          } else {
            console.log('postMessage not found');
          }
        } else {
          console.log('headerInfo not found');
        }
      } else {
        console.log('messageHandlers not found');
      }
    } else {
      console.log('webkit not found in window');
    }
  } catch (e) {
    console.error(e);
  }
};
