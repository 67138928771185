import { createSlice } from '@reduxjs/toolkit';
import { Categories } from '@shared/types/categories';
import { endpoints } from '@shared/services/categories';

const initialState: Categories.List = [];

const { fetchCategories } = endpoints;

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(fetchCategories.matchFulfilled, (_state, action) => {
      return action.payload.data.categories.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    });
  },
});
