import { App } from '@shared/types/app';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: App.SliceState = {
  drawerTheme: 'dark',
  siderCollapsed: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    onSiderCollapse(state, action: PayloadAction<boolean>) {
      state.siderCollapsed = action.payload;
    },
  },
});

export const { onSiderCollapse } = appSlice.actions;
