import { api } from './api';
import { ApiEndpoints } from '@shared/constants/apiEndpoints';
import { transformErrorResponse } from '@shared/utils/transformErrorResponse';

interface FetchCountriesRT {
  data: {
    countries: { code: string; name: string }[];
  };
}

export const countriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchCountries: builder.mutation<FetchCountriesRT, void>({
      transformErrorResponse,
      query: () => ({
        method: 'GET',
        url: ApiEndpoints.countries,
      }),
    }),
  }),
});

export const { endpoints, useFetchCountriesMutation } = countriesApi;
